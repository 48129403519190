<template>
  <div class="row csn-user-account-section csn-ule" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>
            {{ header }}
            <Loading v-if="!type" />
          </h1>
          <p>{{ t('description') }}</p>
        </section>
        <section>
          <h2>{{ t('define_limits') }}</h2>
          <p>{{ t('deposit_limit') }}</p>
        </section>
        <section class="limits-edit">
          <Message :type="messageType" :message="message" :hasBottom="true" />
          <Form class="csn-ule-form" :ref="formRef">
            <div v-if="isTimeLimit" class="csn-ule-input-group">
              <Dropdown
                :dataSource="periodList"
                :label="t('form_period_label')"
                :name="name.PERIOD_TYPE"
                v-model="formData[name.PERIOD_TYPE]"
                :validators="[validator.required]"
                :serverError="serverError[name.PERIOD_TYPE]"
                inputClass="period form-control"
                selectClass="period csn-ule-period"
              />
              <TextField
                :type="inputType.NUMBER"
                :name="name.AMOUNT"
                :label="t('session_time_maximum')"
                :placeholder="t('form_amount_placeholder')"
                v-model="formData[name.AMOUNT]"
                :validators="timeValidators"
                :serverError="serverError[name.AMOUNT]"
                inputClass="deposit-maximum form-control csn-ule-deposit"
                containerClass="csn-ule-deposit-container"
                autocomplete="off"
              />
            </div>

            <div v-else class="csn-ule-input-group">
              <TextField
                :type="inputType.NUMBER"
                :name="name.AMOUNT"
                :label="t('form_amount_label')"
                :placeholder="t('form_amount_placeholder')"
                v-model="formData[name.AMOUNT]"
                :validators="[
                  validator.required,
                  validator.meetsMin(amountMinLimit),
                ]"
                :serverError="serverError[name.AMOUNT]"
                inputClass="deposit-maximum form-control csn-ule-deposit"
                containerClass="csn-ule-deposit-container"
                autocomplete="off"
              />
              <Dropdown
                :dataSource="periodList"
                :label="t('form_period_label')"
                :name="name.PERIOD_TYPE"
                v-model="formData[name.PERIOD_TYPE]"
                :validators="[validator.required]"
                :serverError="serverError[name.PERIOD_TYPE]"
                inputClass="period form-control"
                selectClass="period csn-ule-period"
              />
            </div>

            <div class="row-fluid">
              <Route
                :to="{ name: limitsRouteName }"
                class="btn casino-btn-ver-2 csn-ule-cancel casino-btn casino-btn-default"
              >
                {{ t('cancel_bttn_label') }}
              </Route>
              <button
                class="btn casino-btn-ver-1 casino-btn csn-ule-submit casino-btn-theme-based"
                @click="submitForm"
                :disabled="isSubmitting"
              >
                <ButtonLoader v-if="isSubmitting" />
                <span v-else>{{ t('submit_bttn_label') }}</span>
              </button>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  USER_LIMIT_EDIT,
  Module,
  CURRENT_LOCATION,
  RouteName,
  LimitType,
  COLON,
  EMPTY_STRING,
  EMPTY_ARRAY,
  Digit,
  LIMIT_EDIT_MSG,
} from '@/constants'
import { navigateTo } from '@/helpers'
import { formMixin } from '@/mixins'
import { AuthApi } from '@/api'

const name = {
  AMOUNT: 'amount',
  PERIOD_TYPE: 'periodType',
}

const monthValue = '5'
const hourValue = '2'
const minuteValue = '1'

const formData = {
  [name.AMOUNT]: EMPTY_STRING,
  [name.PERIOD_TYPE]: EMPTY_STRING,
}

export default {
  name: USER_LIMIT_EDIT,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    TextField: () => import('@/components/TextField'),
    Dropdown: () => import('@/components/Dropdown'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Loading: () => import('@/components/atoms/Loading'),
    Route: () => import('@/components/Route'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
    name: () => name,
    limitsRouteName: () => RouteName.USER_LIMITS,
    isTimeLimit() {
      return this.type === LimitType.TIME
    },
    t() {
      return this.$createComponentTranslator(USER_LIMIT_EDIT)
    },
    type() {
      return this.CURRENT_LOCATION?.params?.type
    },
    header() {
      const limitType = this.type ? this.t(this.type) : EMPTY_STRING

      return `${this.t('title')}${COLON} ${limitType}`
    },
    periodList() {
      return this.isTimeLimit
        ? [
            { name: this.t('hour'), value: hourValue },
            { name: this.t('minute'), value: minuteValue },
          ]
        : [
            { name: this.t('month'), value: monthValue },
            { name: this.t('week'), value: '4' },
            { name: this.t('day'), value: '3' },
          ]
    },
    isPeriodMinute() {
      return this.formData[name.PERIOD_TYPE] === minuteValue
    },
    timeValidators() {
      return [
        this.validator.required,
        ...(this.isPeriodMinute
          ? [this.validator.meetsMax(Digit.FIFTY_NINE)]
          : EMPTY_ARRAY),
      ]
    },
    amountMinLimit: () => Digit.ONE,
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        Object.keys(this.serverError).forEach((key) => {
          if (this.formData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[key]

          this.serverError = newServerError
        })
      },
    },
  },
  methods: {
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true

      try {
        const request = {
          user_limits_edit: {
            amount: Number(this.formData[name.AMOUNT]),
            period_type: Number(this.formData[name.PERIOD_TYPE]),
          },
        }

        const response = await AuthApi.postUserLimits({
          type: this.type,
          request,
        })

        if (!this.validateResponse(response)) {
          return
        }

        const { state, msg, data } = response

        if (state === this.responseState.ERROR) {
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg

          if (data.errors) {
            Object.keys(data.errors).forEach((key) => {
              this.serverError[key] = data.errors[key].msg
            })

            this.formDataSubmitCopy = { ...this.formData }
          }
          return
        }

        if (state === this.responseState.OK) {
          this.formDataSubmitCopy = {}
          sessionStorage.setItem(LIMIT_EDIT_MSG, msg)

          navigateTo({ name: RouteName.USER_LIMITS })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
  created() {
    this.formData[name.PERIOD_TYPE] = this.isTimeLimit ? hourValue : monthValue
  },
}
</script>
